import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CouponForm } from "./CouponForm";

const validCoupons = [
  {
    text: "WELCOME",
    discount: 50,
    id: "Pig68EMI", // dev -> "7E4buyMv"
  },
  {
    text: "KNOJI50",
    discount: 50,
    id: "r5YvQ5lF",
  },
  {
    text: "30DISCOUNT",
    discount: 30,
    id: "bVkDC45t",
  },
];

const calculatePrice = (basePrice, couponObj) => {
  if (!couponObj) return basePrice;
  const priceDiscounted = basePrice * (1 - couponObj.discount / 100);
  return priceDiscounted.toFixed(2);
};

export const CheckoutForm = ({
  subscriptionData,
  currency,
  clientSecret,
  handleOkCardDetails,
  setIsLoading,
  setError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const allParams = Object.fromEntries(queryParams.entries());
  const shouldShowPromo = allParams.showPromoCode !== undefined;

  const [coupon, setCoupon] = useState(null);
  const [appliedCouponCode, setAppliedCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(null);
  const [price, setPrice] = useState(subscriptionData?.price);

  const handleUpdatePromoCode = (promoCode) => {
    setAppliedCouponCode(promoCode);
    const appliedCoupon = validCoupons.filter((c) => c.text === promoCode)[0];
    if (appliedCoupon) {
      setCoupon(appliedCoupon);
      setIsCouponValid(Boolean(appliedCoupon));
      setPrice(calculatePrice(subscriptionData?.price, appliedCoupon));
    } else {
      setCoupon(null);
      setIsCouponValid(false);
      setPrice(subscriptionData?.price);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded properly.");
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      const msg = result?.error?.message || "Error setting up subscription";
      setError(msg);
      setIsLoading(false);
    } else {
      setError("");
      handleOkCardDetails(result, coupon?.id);
    }
  };

  const DiscountForm = () => {
    if (!shouldShowPromo) return;

    return (
      <div className="mb-4">
        <CouponForm
          visible={true}
          onCouponValidate={handleUpdatePromoCode}
          isCorrect={isCouponValid}
          isWrong={isCouponValid === false}
          appliedCouponCode={appliedCouponCode}
        />
      </div>
    );
  };

  return (
    <div className="bg-gray-200 px-10 py-7 rounded-lg w-full max-w-md mx-3">
      <form onSubmit={handleFormSubmit}>
        <div className="text-black text-center border-y border-black mb-7 p-3">
          You are about to subscribe to <br />{" "}
          <b>
            {subscriptionData?.name} for {currency?.symbol}
            {price}/{subscriptionData?.cadence}
          </b>
        </div>

        <DiscountForm />

        <CardElement className="p-2 rounded-lg border border-gray-600" />

        <button
          type="submit"
          className="w-full mt-5 bg-indigo-600 border border-transparent rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-pink-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};
