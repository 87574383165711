import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

export const ModalConfirm = ({
  title = "Confirm Choice",
  text = "Are you sure?",
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          marginTop: "auto",
          marginBottom: "auto",
          "@media (max-width: 640px)": {
            marginLeft: "1rem",
            marginRight: "1rem",
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <p>{text}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleCancel}
            mr={2}
          >
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
