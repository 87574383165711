import React, { useState } from "react";
import {
  ArrowTopRightOnSquareIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import { MatchRateIcon } from "./matchRateIcon";
import { capitalize } from "../../utils";
import BinIcon from "../../assets/images/bin-icon.svg";
import { ModalWithBody } from "./ModalWithBody";

const styleContainer =
  "flex flex-col bg-gray-700 rounded-md p-2 pr-1 md:p-2 md:px-4 md:py-4";
const styleTitle =
  "flex flex-row items-center space-x-2 text-xl md:text-3xl font-bold ";
const styleMatchSmall =
  "flex flex-row items-center text-md md:text-xl underline underline-offset-2 pt-1 mb-2";
const styleSubTitle =
  "flex flex-row items-center space-x-2 text-lg md:text-2xl";
const styleExpandedTitle = "font-bold";
const styleExpandedText = "text-justify leading-snug no-wrap";
const styleActionIcon = "h-8 w-8 hover:h-12 hover:w-12 cursor-pointer";

const msgRejectPlaceholder = `(optional)
Why you don't like this suggestion.`;

const msgSubmitPlaceholder = `(optional)
Add relevant details (account creation details, etc.).`;

const DateDisplay = ({ timestamp }) => {
  const date = new Date(Number(timestamp));
  const ds = date.toDateString();
  const dFormatted = [
    ds.slice(0, 3),
    ", ",
    ds.slice(3, -5),
    ", ",
    ds.slice(-5),
  ];

  return <div>{dFormatted}</div>;
};

export const CardSubmission = ({
  data,
  onSubmit = () => {},
  onReject = () => {},
  isRecruiter = false,
  isComplete = false,
  isDiscarded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMatchExpanded, setIsMatchExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null); // 'submit', 'reject'

  const {
    companyName,
    companyDescription,
    jobTitle,
    jobDescription,
    applicationUrl,
    companyUrl,
    salary,
    createdAt,
    matchRate,
    additionalInfo,
    messageDiscard,
  } = data;

  const handleExpandAndCollapse = () => {
    setIsMatchExpanded(false);
    setIsExpanded(!isExpanded);
  };

  const handleMatchClick = () => {
    setIsExpanded(false);
    setIsMatchExpanded(!isMatchExpanded);
  };

  const handleReject = () => {
    console.log("reject was clicked");
    setIsModalOpen(true);
    setModalType("reject");
  };

  const handleSubmitClickClick = () => {
    console.log("submit was clicked");
    setIsModalOpen(true);
    setModalType("submit");
  };

  const handleSubmit = (additionalInfo) => {
    // additionalInfo is the optional additional text provided
    const postData = {
      ...data,
      additionalInfo,
    };

    onSubmit(postData);
  };

  const handleModalReset = () => {
    setIsModalOpen(false);
    setModalType(null);
  };

  const MainContent = () => (
    <div className="flex flex-row justify-between">
      <div
        id="card-submission-text-content"
        className="flex flex-row sm:space-x-4 w-full"
      >
        <div className="my-auto hidden sm:block">
          <MatchRateIcon matchRate={matchRate} onClick={handleMatchClick} />
        </div>
        <div className="pr-1">
          {matchRate && (
            <div
              className={`block sm:hidden ${styleMatchSmall}`}
              onClick={handleMatchClick}
            >
              Match: {matchRate}%
            </div>
          )}
          <div className={styleTitle}>{capitalize(companyName)}</div>
          <div className={styleSubTitle}>{capitalize(jobTitle)}</div>
          <div onClick={handleExpandAndCollapse} className="underline">
            {isExpanded ? "See less" : "See more"}
          </div>
          <DiscardReason />
        </div>
      </div>
      <div
        id="card-submission-action-content"
        className="flex flex-row justify-between space-x-2 md:space-x-4 sm:min-w-1/12 my-auto items-center"
      >
        {!isDiscarded && (
          <img
            src={BinIcon}
            className="h-6 min-w-6 sm:w-6 invert hover:h-10 hover:w-10"
            onClick={handleReject}
          />
        )}
        {isRecruiter && !isComplete && (
          <CheckBadgeIcon
            className={`${styleActionIcon}`}
            onClick={handleSubmitClickClick}
          />
        )}
      </div>
    </div>
  );

  const SeeMore = ({ link }) => (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center ml-1 underline"
    >
      See more
      <ArrowTopRightOnSquareIcon className="h-5 w-5 text-gray-400 ml-1 pb-px" />
    </a>
  );

  function TextWithSeeMore({ text, url }) {
    return (
      <p className={styleExpandedText}>
        {text}
        <SeeMore link={url} />
      </p>
    );
  }

  const ExpandedContent = () => {
    if (!isExpanded) return;

    return (
      <div className="mt-2 space-y-4 border-t sm:border-none pt-2">
        <div>
          <div className={styleExpandedTitle}>The company:</div>
          <TextWithSeeMore text={companyDescription} url={companyUrl} />
        </div>
        <div>
          <div className={styleExpandedTitle}>The role:</div>
          <TextWithSeeMore text={jobDescription} url={applicationUrl} />
        </div>
        {salary && (
          <div>
            <div className={styleExpandedTitle}>The salary:</div>
            <div className={styleExpandedText}>{salary}</div>
          </div>
        )}
        <div>
          <div className={styleExpandedTitle}>Date created:</div>
          <DateDisplay timestamp={createdAt} />
        </div>
        {additionalInfo && (
          <div>
            <div className={styleExpandedTitle}>Additional info:</div>
            <div className={styleExpandedText}>{additionalInfo}</div>
          </div>
        )}
      </div>
    );
  };

  const ExpandedMatchInfo = () => {
    if (!isMatchExpanded) return;

    return (
      <div className="mt-2 space-y-4 border-t sm:border-none pt-2">
        <div>
          <div className={styleExpandedTitle}>Pros:</div>
          <div className={styleExpandedText}>{data?.matchPros}</div>
        </div>
        <div>
          <div className={styleExpandedTitle}>Cons:</div>
          <div className={styleExpandedText}>{data?.matchCons}</div>
        </div>
      </div>
    );
  };

  const ModalContent = () => {
    if (!isModalOpen) return;

    if (modalType === "submit") {
      return (
        <ModalWithBody
          title={"Submit Application"}
          text={"Have you processed this application now?"}
          confirmButtonText="Submit"
          placeholderBody={msgSubmitPlaceholder}
          onClose={handleModalReset}
          onSubmit={handleSubmit}
        />
      );
    }

    if (modalType === "reject") {
      return (
        <ModalWithBody
          title={isComplete ? "Discard job application" : "Reject Suggestion"}
          text={
            isComplete
              ? "Reason for discarding application"
              : "I don't like this suggestion. Please discard it."
          }
          confirmButtonText="Discard"
          placeholderBody={
            isComplete
              ? "Application rejected, no longer interested, etc."
              : msgRejectPlaceholder
          }
          onClose={handleModalReset}
          onSubmit={(msg) => onReject(data, msg)}
        />
      );
    }
  };

  const DiscardReason = () => {
    if (!isDiscarded || messageDiscard === "") return;

    return (
      <div className="flex flex-row text-lg pt-2">
        <div className="font-bold ">Discarded because:</div>
        <div className="pl-1">{messageDiscard}</div>
      </div>
    );
  };

  return (
    <div className={styleContainer}>
      <MainContent />
      <ExpandedContent />
      <ExpandedMatchInfo />
      <ModalContent />
    </div>
  );
};
