export const URL_BASE_BACKEND =
  process.env.REACT_APP_URL_BASE_BACKEND || "http://localhost:5000";

export const URL_SUBSCRIBE_WAITLIST = `${URL_BASE_BACKEND}/subscribe-to-waitlist`;
export const URL_LIST_USERS = `${URL_BASE_BACKEND}/list-users`;
export const URL_SUGGESTIONS_ADD = `${URL_BASE_BACKEND}/submissions/add-suggestion`;
export const URL_SUGGESTIONS_LIST = `${URL_BASE_BACKEND}/submissions/list-suggestions`;
export const URL_SUBMISSIONS_ADD = `${URL_BASE_BACKEND}/submissions/complete-suggestion`;
export const URL_SUBMISSIONS_LIST = `${URL_BASE_BACKEND}/submissions/list-completions`;
export const URL_SUBMISSIONS_REJECT = `${URL_BASE_BACKEND}/submissions/reject-suggestion`;
export const URL_DISCARDED_LIST = `${URL_BASE_BACKEND}/submissions/list-rejections`;
export const URL_GET_USER_INFO = `${URL_BASE_BACKEND}/get-user-data`;
export const URL_UPDATE_USER_INFO = `${URL_BASE_BACKEND}/update-user-data`;
export const URL_SUBSCRIBE_INTENT = `${URL_BASE_BACKEND}/intent-payment`;
export const URL_SUBSCRIBE_CREATE = `${URL_BASE_BACKEND}/subscription`;
export const URL_SUBSCRIBE_GET = `${URL_BASE_BACKEND}/subscription`;
export const URL_SUBSCRIBE_CANCEL = `${URL_BASE_BACKEND}/cancel-subscription`;
export const URL_CONTACT_US = `${URL_BASE_BACKEND}/contact-us`;
export const URL_IP_INFO = `${URL_BASE_BACKEND}/ip-info`;
export const URL_LOG_EVENT = `${URL_BASE_BACKEND}/log-event`;
export const URL_GET_IP = "https://api64.ipify.org?format=json"


export const urlRegistration = {
  cvUpload: "/registration/cv-upload",
  jobTitles: "/registration/job-titles",
  locationPreferences: "/registration/location-preferences",
  otherPreferences: "/registration/other-preferences",
  review: "/registration/review",
};
