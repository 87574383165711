/**
 * Data returned looks like this:
 * ```
 * {
 *   Lite: {
 *     name: "Lite",
 *     cadence: "weekly",
 *     numSubmissionsPerWeek: 10,
 *     price: 99,
 *     includes: ["foo"],
 *     doesNotInclude: ["bar"],
 *     planId: {
 *       usd: "plan_id",
 *       eur: "plan_id",
 *       gbp: "plan_id",
 *     }
 *   }
 * }
 * ```
 */
export const getPricingMap = (isProd = true, isWeek = true) => {
  const env = isProd ? "prod" : "dev";
  const cadence = isWeek ? "week" : "month";

  return dataPricing[env][cadence];
};

export const infoMessagesSubscription = [
  "Suggestions are job positions which we will apply for, but which you can discard if you do not like them.",
  "These are applications that we fully complete on your behalf.",
  "This is a person exclusively dedicated to finding you a job, and to providing you with support throughout the job application process.",
  "We will provide you with a review of your CV and suggest changes we believe can be helpful in your job search.",
  "We create a custom cover letter specifically tailored to each job position we apply for.",
  "This includes a fully researched document with all the info about the company and the position, as well as a mock interview to help you prepare.",
];

export const getSubscriptionByPlanId = (planId) => {
  if (!planId) return;

  return subMap[planId];
};

const LiteFeatures = {
  includes: [
    "100 job suggestions <br />(per week)",
    "25 job applications <br />(per week)",
  ],
  doesNotInclude: [
    "Personal human assistant",
    "CV reviewed by recruiter",
    "Custom cover letters",
    "Interview preparations",
  ],
};

const ProFeatures = {
  includes: [
    "200 job suggestions <br />(per week)",
    "<b>50 job applications <br />(per week)</b>",
    "<b>Personal human assistant</b>",
    "CV reviewed by recruiter",
  ],
  doesNotInclude: ["Custom cover letters", "Interview preparations"],
};

const PremiumFeaturesWeek = {
  includes: [
    "400 job suggestions <br />(per week)",
    "100 job applications <br />(per week)",
    "Personal human assistant",
    "CV reviewed by recruiter",
    "<b>Custom cover letters</b>",
    "<b>Interview preparations</b>",
  ],
  doesNotInclude: [],
};

const PremiumFeaturesMonth = {
  includes: [
    "300 job suggestions <br />(per week)",
    "75 job applications <br />(per week)",
    "Personal human assistant",
    "CV reviewed by recruiter",
    "<b>Custom cover letters</b>",
    "<b>Interview preparations</b>",
  ],
  doesNotInclude: [],
};

const LiteWeek = {
  name: "Lite",
  cadence: "week",
  numSubmissionsPerWeek: 25,
  price: 79,
  includes: LiteFeatures.includes,
  doesNotInclude: LiteFeatures.doesNotInclude,
  planId: {
    usd: "price_1PIqEAKoQlOr3NM8BrWtbZjd",
    eur: "price_1PIqE2KoQlOr3NM8SsthzFFU",
    gbp: "price_1PIqDUKoQlOr3NM8z892fMZU",
  },
};

const LiteMonth = {
  name: "Lite",
  cadence: "month",
  numSubmissionsPerWeek: 25,
  price: 299,
  includes: LiteFeatures.includes,
  doesNotInclude: LiteFeatures.doesNotInclude,
  planId: {
    usd: "price_1PIqEoKoQlOr3NM8xd9AUSDB",
    eur: "price_1PIqEdKoQlOr3NM8OdXKlSX9",
    gbp: "price_1PIqEwKoQlOr3NM8MQA7JgHF",
  },
};

const LiteOld = {
  name: "Lite",
  numSubmissionsPerWeek: 5,
  planId: "price_1P13eMKoQlOr3NM8LkNgQztm",
};

const ProOld = {
  name: "Pro",
  numSubmissionsPerWeek: 15,
  planId: "price_1P13gSKoQlOr3NM8SGxXRA80",
};

const PremiumOld = {
  name: "Premium",
  numSubmissionsPerWeek: 40,
  planId: "price_1P13h7KoQlOr3NM8fuOSSUYL",
};

const ProWeek = {
  name: "Pro",
  cadence: "week",
  numSubmissionsPerWeek: 50,
  price: 99,
  includes: ProFeatures.includes,
  doesNotInclude: ProFeatures.doesNotInclude,
  planId: {
    usd: "price_1PIqMAKoQlOr3NM8JIo02ypm",
    eur: "price_1PIqM0KoQlOr3NM8TykGi1vj",
    gbp: "price_1PIqMOKoQlOr3NM8swSZJ5yl",
  },
};

const ProMonth = {
  name: "Pro",
  cadence: "month",
  numSubmissionsPerWeek: 50,
  price: 399,
  includes: ProFeatures.includes,
  doesNotInclude: ProFeatures.doesNotInclude,
  planId: {
    usd: "price_1PIqLgKoQlOr3NM8w2d95YWS",
    eur: "price_1PIqLRKoQlOr3NM8ye7bwuvD",
    gbp: "price_1PIqLoKoQlOr3NM8ByURpS8b",
  },
};

const PremiumWeek = {
  name: "Premium",
  cadence: "week",
  numSubmissionsPerWeek: 100,
  price: 149,
  includes: PremiumFeaturesWeek.includes,
  doesNotInclude: PremiumFeaturesMonth.doesNotInclude,
  planId: {
    usd: "price_1PIqPHKoQlOr3NM8MZDVoVed",
    eur: "price_1PIqPeKoQlOr3NM8jGDTwbRg",
    gbp: "price_1PIqPPKoQlOr3NM8qegQx0lF",
  },
};

const PremiumMonth = {
  name: "Premium",
  cadence: "month",
  numSubmissionsPerWeek: 75,
  price: 499,
  includes: PremiumFeaturesMonth.includes,
  doesNotInclude: PremiumFeaturesMonth.doesNotInclude,
  planId: {
    usd: "price_1PIqP0KoQlOr3NM821SNrorP",
    eur: "price_1PIqOfKoQlOr3NM82ytDTZaS",
    gbp: "price_1PIqOsKoQlOr3NM8AmUyN6c4",
  },
};

const TestWeek = {
  name: "Test",
  cadence: "week",
  numSubmissionsPerWeek: 10,
  price: 1,
  includes: PremiumFeaturesMonth.includes,
  doesNotInclude: PremiumFeaturesMonth.doesNotInclude,
  planId: {
    usd: "price_1OwQJTKoQlOr3NM8YaPxE9mm",
    eur: "price_1OwQJTKoQlOr3NM8YaPxE9mm",
    gbp: "price_1OwQJTKoQlOr3NM8YaPxE9mm",
  },
};

const TestMonth = {
  ...TestWeek,
  cadence: "month",
};

const mapPlanToDev = (plan) => {
  return {
    ...plan,
    planId: {
      usd: "price_1OwQ3hKoQlOr3NM8KVo9DN9A",
      eur: "price_1OwQ3hKoQlOr3NM8KVo9DN9A",
      gbp: "price_1OwQ3hKoQlOr3NM8KVo9DN9A",
    },
  };
};

const dataPricing = {
  prod: {
    week: {
      Lite: LiteWeek,
      Pro: ProWeek,
      Premium: PremiumWeek,
      Test: TestWeek,
    },
    month: {
      Lite: LiteMonth,
      Pro: ProMonth,
      Premium: PremiumMonth,
      Test: TestMonth,
    },
  },
  dev: {
    week: {
      Lite: mapPlanToDev(LiteWeek),
      Pro: mapPlanToDev(ProWeek),
      Premium: mapPlanToDev(PremiumWeek),
      Test: mapPlanToDev(TestWeek),
    },
    month: {
      Lite: mapPlanToDev(LiteMonth),
      Pro: mapPlanToDev(ProMonth),
      Premium: mapPlanToDev(PremiumMonth),
      Test: mapPlanToDev(TestWeek),
    },
  },
};

const subMap = {
  price_1PIqEAKoQlOr3NM8BrWtbZjd: LiteWeek,
  price_1PIqE2KoQlOr3NM8SsthzFFU: LiteWeek,
  price_1PIqDUKoQlOr3NM8z892fMZU: LiteWeek,
  price_1PIqEoKoQlOr3NM8xd9AUSDB: LiteMonth,
  price_1PIqEdKoQlOr3NM8OdXKlSX9: LiteMonth,
  price_1PIqEwKoQlOr3NM8MQA7JgHF: LiteMonth,
  price_1PIqMAKoQlOr3NM8JIo02ypm: ProWeek,
  price_1PIqM0KoQlOr3NM8TykGi1vj: ProWeek,
  price_1PIqMOKoQlOr3NM8swSZJ5yl: ProWeek,
  price_1PIqLgKoQlOr3NM8w2d95YWS: ProMonth,
  price_1PIqLRKoQlOr3NM8ye7bwuvD: ProMonth,
  price_1PIqLoKoQlOr3NM8ByURpS8b: ProMonth,
  price_1PIqPHKoQlOr3NM8MZDVoVed: PremiumWeek,
  price_1PIqPeKoQlOr3NM8jGDTwbRg: PremiumWeek,
  price_1PIqPPKoQlOr3NM8qegQx0lF: PremiumWeek,
  price_1PIqP0KoQlOr3NM821SNrorP: PremiumMonth,
  price_1PIqOfKoQlOr3NM82ytDTZaS: PremiumMonth,
  price_1PIqOsKoQlOr3NM8AmUyN6c4: PremiumMonth,
  price_1P13eMKoQlOr3NM8LkNgQztm: LiteOld,
  price_1P13gSKoQlOr3NM8SGxXRA80: ProOld,
  price_1P13h7KoQlOr3NM8fuOSSUYL: PremiumOld,
  price_1OwQJTKoQlOr3NM8YaPxE9mm: TestWeek,
  price_1OwQ3hKoQlOr3NM8KVo9DN9A: TestWeek,
  price_1OyDG1KoQlOr3NM8X2RoPymi: TestWeek,
};
