import React, { useState, useEffect } from "react";
import { CardSubmission } from "./CardSubmission";
import { stylePageTitle } from "../../assets/classnames";
import { useSubmissionsApi } from "../../context/useSubmissionsApi";
import { useUserData } from "../../context/useUserData";
import { AlertAll } from "../AlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import { CardSuggestionsSimplified } from "../../routes/user-profile/Cards";
import { ButtonCustom } from "../cta/ButtonCustom";
import { SortDropdown, sortSuggestions } from "./SortDropdown";

export const NewSuggestionsPage = () => {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const { isRecruiter } = useUserData();
  const {
    isLoading,
    isUpdating,
    rejectSuggestion,
    fetchSuggestions,
    completeSubmission,
  } = useSubmissionsApi();

  const [suggestions, setSuggestions] = useState([]);
  const [currentSort, setCurrentSort] = useState("most_recent");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [timeSinceLastReview, setTimeSinceLastReview] = useState("");

  console.log("new Suggestions Page", {
    isLoading,
    isUpdating,
    error,
    success,
    suggestions,
  });

  const onGetSuggestions = (res) => {
    setSuggestions(sortSuggestions(res?.data?.docList));
    setTimeSinceLastReview(res?.data?.timeSinceLastReview || "");
  };

  useEffect(() => {
    setError(false);
    fetchSuggestions(idUser, onGetSuggestions, onError);
  }, [idUser]);

  useEffect(() => {
    if (isUpdating) return;

    setError(false);
    fetchSuggestions(idUser, onGetSuggestions, onError);
  }, [isUpdating]);

  useEffect(() => {
    // set a timer to reset the success/error states
    if (!success && !error) return; // Do nothing if both are false

    const timer = setTimeout(() => {
      setError(false);
      setSuccess(false);
    }, 7000);

    // Clear the timeout if the component unmounts or if success/error change
    return () => clearTimeout(timer);
  }, [success, error]);

  const onError = (e) => setError(e);
  const onSuccess = () => setSuccess(true);

  const onCompleteSuggestion = (data) => {
    console.log("inside onCompleteSuggestion", data);
    completeSubmission(data, onSuccess, onError);
  };

  const onRejectSuggestion = (data, msg) => {
    console.log("inside onRejectSuggestion", { data, msg });

    rejectSuggestion(data, msg, idUser, onSuccess, onError);
  };

  const TitleContent = () => (
    <>
      <div className={`${stylePageTitle} text-main-blue`}>Suggestions</div>
      <p className="text-md max-w-3xl pb-4">
        These are the applications we are going to submit on your behalf.
        <br className="" /> If you don't like any of the suggestions below,
        please discard them by clicking on the bin icon.
      </p>
    </>
  );

  const AlertContent = () => (
    <AlertAll
      error={error}
      success={success}
      isUpdating={isLoading || isUpdating}
      msgLoading={isLoading ? "Loading suggestions" : "Processing..."}
      msgError="Error loading suggestions"
      msgSuccess={
        isRecruiter
          ? "Success submitting application"
          : "Success discarding suggestion"
      }
    />
  );

  const SuggestionsContent = () => {
    if (suggestions.length === 0) return;

    const sortedSuggestions = sortSuggestions(suggestions, currentSort);

    return (
      <div>
        <div className="space-y-2">
          {sortedSuggestions.map((dataSuggestion, index) => (
            <CardSubmission
              data={dataSuggestion}
              isRecruiter={isRecruiter}
              onReject={onRejectSuggestion}
              onSubmit={onCompleteSuggestion}
              key={`card-submission-${index}`}
            />
          ))}
        </div>
      </div>
    );
  };

  const NoSuggestionsContent = () => {
    if (suggestions.length > 0) return;

    return <CardSuggestionsSimplified />;
  };

  const BackHomeContent = () => (
    <div className="flex justify-center pt-12">
      <ButtonCustom
        text="Back Home"
        onClick={() => navigate("/profile")}
        isHighlight={false}
      />
    </div>
  );

  const ReviewStats = () => {
    if (!isRecruiter) return;

    return (
      <div>
        <div className="text-lg font-bold py-2">Time since last review: {timeSinceLastReview}</div>
      </div>
    );
  };

  return (
    <div>
      <TitleContent />
      <AlertContent />
      <ReviewStats />
      <SortDropdown currentSort={currentSort} onSortChange={setCurrentSort} />
      <SuggestionsContent />
      <NoSuggestionsContent />
      <BackHomeContent />
    </div>
  );
};
